import { createRouteRef, createSubRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'release-notes',
});

export const releaseNotesCreateRouteRef = createSubRouteRef({
  id: 'release-notes/create',
  path: '/create',
  parent: rootRouteRef,
});

export const releaseNotesEditRouteRef = createSubRouteRef({
  id: 'release-notes/edit',
  path: '/edit/:id',
  parent: rootRouteRef,
});

export const releaseNotesViewRouteRef = createSubRouteRef({
  id: 'release-notes/view',
  path: '/view/:id',
  parent: rootRouteRef,
});
