import { createApiRef } from '@backstage/core-plugin-api';
import {
  ReleaseNote,
  ReleaseNotesList
} from '@internal/backstage-plugin-release-notes-common';
import { DateTime } from 'luxon';
import { CreateReleaseNoteRequest } from './types';

export const releaseNotesApiRef = createApiRef<ReleaseNotesApi>({
  id: 'plugin.release-notes.service',
});

export interface ReleaseNotesApi {
  releaseNotes(opts: {
    max?: number;
    page?: number;
    category?: string;
  }): Promise<ReleaseNotesList>;
  releaseNoteByID(id: string): Promise<ReleaseNote>;

  createReleaseNote(request: CreateReleaseNoteRequest): Promise<ReleaseNote>;
  updateReleaseNote(
    id: string,
    request: CreateReleaseNoteRequest,
  ): Promise<ReleaseNote>;
  deleteReleaseNoteByID(id: string): Promise<void>;

  lastSeenDate(): DateTime;
  markLastSeenDate(date: DateTime): void;
}
