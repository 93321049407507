import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';

export const selfService = createPlugin({
  id: 'selfService',
  routes: {
    root: rootRouteRef,
  },
});

export const SelfServicePage = selfService.provide(
  createRoutableExtension({
    name: 'SelfServicePage',
    component: () => import('./components/SelfServiceLayout').then(m => m.SelfServiceLayout),
    mountPoint: rootRouteRef,
  }),
);
