import {
  ProfileInfo,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { LDContext, useLDClient } from 'launchdarkly-react-client-sdk';
import React, { useEffect } from 'react';

/**
 * Empty component responsible to update the user context of LaunchDarkly.
 *
 * @remarks
 * This component is responsible to update the LDContext with the info of the currently logged in User.
 * When this component is rendered, we know the SignIn process was completed so we have info about the connected User.
 *
 * @public
 */
export function LaunchDarklyComponent(): JSX.Element {
  const ldClient = useLDClient();
  const identityApi = useApi(identityApiRef);

  useEffect(() => {
    async function asyncFetchUserInfo(): Promise<ProfileInfo | undefined> {
      try {
        const userInfo: ProfileInfo = await identityApi.getProfileInfo();
        return userInfo;
      } catch (error) {
        console.error('Error fetching user information:', error);
        return undefined;
      }
    }

    async function updateLDContext() {
      const userInfo = await asyncFetchUserInfo();

      if (userInfo !== undefined) {
        const contextWithIdentity: LDContext = {
          kind: 'user',
          key: userInfo.email,
          email: userInfo.email,
          name: userInfo.displayName,
        };

        ldClient?.identify(contextWithIdentity, undefined, () => {
          console.log(`New LD context set with ${userInfo.email}`);
        });
      }
    }

    updateLDContext();
  }, []);

  return <></>;
}
