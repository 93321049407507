import { PageTheme, createUnifiedTheme, pageTheme as defaultPageThemes, palettes } from '@backstage/theme';

import { alpha } from '@material-ui/core/styles';

const pageThemesFontColorOverride: Record<string, PageTheme> = {};
Object.keys(defaultPageThemes).map(key => {
  pageThemesFontColorOverride[key] = {
    ...defaultPageThemes[key],
    backgroundImage: 'unset',
    fontColor: '#0B0D0E',
  };
});

const darkPageThemesFontColorOverride: Record<string, PageTheme> = {};
Object.keys(defaultPageThemes).map(key => {
  darkPageThemesFontColorOverride[key] = {
    ...defaultPageThemes[key],
    backgroundImage: 'unset',
    fontColor: '#F7F7F7',
  };
});

export const caeDarkTheme = createUnifiedTheme({
  palette: {
    ...palettes.dark,
    primary: {
      main: '#A7C1F9',
    },
    secondary: {
      main: '#9D97DA',
    },
    info: {
      main: '#608FF4',
    },
    warning: {
      main: '#FFAB00',
    },
    error: {
      main: '#e53935',
    },
    success: {
      main: '#2CBE7C',
    },
    navigation: {
      ...palettes.dark.navigation,
      indicator: '#2969F2',
      selectedColor: '#A7C1F9',
      color: '#D2D5D7',
      navItem: {
        hoverBackground: '#2256C6',
      },
    },
  },
  fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
  pageTheme: darkPageThemesFontColorOverride,
  defaultPageTheme: 'home',
  components: {
    BackstageHeader: {
      styleOverrides: {
        header: ({ theme }) => ({
          backgroundImage: 'unset',
          boxShadow: '0 0.3rem 2.4rem #00000014',
          paddingBottom: theme.spacing(1),
        }),
        title: ({ theme }) => ({
          color: theme.page.fontColor,
          fontWeight: 900,
        }),
        subtitle: ({ theme }) => ({
          color: alpha(theme.page.fontColor, 0.8),
        }),
        type: ({ theme }) => ({
          color: alpha(theme.page.fontColor, 0.8),
        }),
      },
    },
    BackstageHeaderTabs: {
      styleOverrides: {
        defaultTab: {
          fontSize: 'inherit',
          textTransform: 'none',
        },
      },
    },
    BackstageItemCardHeader: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.primary,
          backgroundColor: alpha('#163984', 0.65),
        }),
      },
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: 8,
        },
      },
    },
  },
});

export const caeLightTheme = createUnifiedTheme({
  palette: {
    ...palettes.light,
    primary: {
      main: '#2969F2',
    },
    secondary: {
      main: '#4C41BB',
    },
    info: {
      main: '#2969F2',
    },
    warning: {
      main: '#FFAB00',
    },
    error: {
      main: '#e53935',
    },
    success: {
      main: '#2CBE7C',
    },
    navigation: {
      ...palettes.light.navigation,
      background: '#FFFFFF',
      indicator: '#2969F2',
      color: '#202528',
      selectedColor: '#2969F2',
      navItem: {
        hoverBackground: '#D3DFFB',
      },
    },
  },
  fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
  pageTheme: pageThemesFontColorOverride,
  defaultPageTheme: 'home',
  components: {
    BackstageHeader: {
      styleOverrides: {
        header: ({ theme }) => ({
          backgroundImage: 'unset',
          boxShadow: '0 0.3rem 2.4rem #00000014',
          paddingBottom: theme.spacing(1),
        }),
        title: ({ theme }) => ({
          color: theme.page.fontColor,
          fontWeight: 900,
        }),
        subtitle: ({ theme }) => ({
          color: alpha(theme.page.fontColor, 0.8),
        }),
        type: ({ theme }) => ({
          color: alpha(theme.page.fontColor, 0.8),
        }),
      },
    },
    BackstageHeaderTabs: {
      styleOverrides: {
        defaultTab: {
          fontSize: 'inherit',
          textTransform: 'none',
        },
      },
    },
    BackstageItemCardHeader: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.primary,
          backgroundColor: alpha('#E6EDFC', 0.65),
        }),
      },
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: 8,
        },
      },
    },
  },
});
