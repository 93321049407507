import { createRouteRef, createSubRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'selfService',
});

export const selfServiceLandingZoneRequestRouteRef = createSubRouteRef({
  id: 'selfService:landingZoneRequest',
  path: '/landingZoneRequest',
  parent: rootRouteRef,
});

export const selfServiceOpenShiftProjectRequestRouteRef = createSubRouteRef({
  id: 'selfService:openShiftRequest',
  path: '/openshiftProjectRequest',
  parent: rootRouteRef,
});
