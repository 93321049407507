import { createApiFactory, createComponentExtension, createPlugin, createRoutableExtension, discoveryApiRef, errorApiRef, fetchApiRef, identityApiRef } from '@backstage/core-plugin-api';

import { releaseNotesApiRef } from '@internal/backstage-plugin-release-notes-react';
import { ReleaseNotesClient } from './api';
import { rootRouteRef } from './routes';

export const releaseNotesPlugin = createPlugin({
  id: 'release-notes',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: releaseNotesApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        identityApi: identityApiRef,
        errorApi: errorApiRef,
        fetchApi: fetchApiRef,
      },
      factory: ({ discoveryApi, identityApi, errorApi, fetchApi }) => {
        return new ReleaseNotesClient({
          discoveryApi: discoveryApi,
          identityApi: identityApi,
          errorApi: errorApi,
          fetchApi: fetchApi,
        });
      },
    }),
  ],
});

export const ReleaseNotesPage = releaseNotesPlugin.provide(
  createRoutableExtension({
    name: 'ReleaseNotesPage',
    component: () =>
      import('./components/Router').then(m => m.Router),
    mountPoint: rootRouteRef,
  }),
);

export const ReleaseNotesCard = releaseNotesPlugin.provide(
  createComponentExtension({
    name: 'ReleaseNotesCard',
    component: {
      lazy: () =>
        import('./components/ReleaseNotesCard/ReleaseNotesCard').then(m => m.ReleaseNotesCard),
    },
  }),
);

export const NewReleaseNoteBanner = releaseNotesPlugin.provide(
  createComponentExtension({
    name: 'NewReleaseNoteBanner',
    component: {
      lazy: () =>
        import('./components/NewReleaseNoteBanner').then(
          m => m.NewReleaseNoteBanner,
        ),
    },
  }),
)
