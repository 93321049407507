import { WebStorage } from '@backstage/core-app-api';
import {
    DiscoveryApi,
    ErrorApi,
    FetchApi,
    IdentityApi
} from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';
import { ReleaseNote, ReleaseNotesList } from '@internal/backstage-plugin-release-notes-common';
import { CreateReleaseNoteRequest, ReleaseNotesApi } from '@internal/backstage-plugin-release-notes-react';
import { DateTime } from 'luxon';

const lastSeenKey = 'user_last_seen_date';


type ReleaseNotesClientOptions = {
    discoveryApi: DiscoveryApi;
    identityApi: IdentityApi;
    errorApi: ErrorApi;
    fetchApi: FetchApi;
};

export class ReleaseNotesClient implements ReleaseNotesApi {
    private readonly discoveryApi: DiscoveryApi;
    private readonly identityApi: IdentityApi;
    private readonly webStorage: WebStorage;
    private readonly fetchApi: FetchApi;

    constructor(opts: ReleaseNotesClientOptions) {
        this.discoveryApi = opts.discoveryApi;
        this.identityApi = opts.identityApi;
        this.webStorage = new WebStorage('release-notes', opts.errorApi);
        this.fetchApi = opts.fetchApi;
    }

    private async fetch<T = any>(input: string, init?: RequestInit): Promise<T> {
        const baseApiUrl = await this.discoveryApi.getBaseUrl('release-notes');
        const { token } = await this.identityApi.getCredentials();

        const headers: HeadersInit = new Headers(init?.headers);
        if (token && !headers.has('authorization')) {
            headers.set('authorization', `Bearer ${token}`);
        }

        return this.fetchApi
            .fetch(`${baseApiUrl}${input}`, {
                ...init,
                headers,
            })
            .then(async response => {
                if (!response.ok) {
                    throw await ResponseError.fromResponse(response);
                }

                return response.json() as Promise<T>;
            });
    }

    private async delete(input: string, init?: RequestInit): Promise<void> {
        const baseApiUrl = await this.discoveryApi.getBaseUrl('release-notes');
        const { token } = await this.identityApi.getCredentials();

        const headers: HeadersInit = new Headers(init?.headers);
        if (token && !headers.has('authorization')) {
            headers.set('authorization', `Bearer ${token}`);
        }

        return this.fetchApi
            .fetch(`${baseApiUrl}${input}`, {
                ...{ method: 'DELETE' },
                headers,
            })
            .then(async response => {
                if (!response.ok) {
                    throw await ResponseError.fromResponse(response);
                }
            });
    }

    async releaseNotes({
        max,
        page,
    }: {
        max?: number;
        page?: number;
    }): Promise<ReleaseNotesList> {
        const params = new URLSearchParams();
        if (max) {
            params.append('max', max.toString());
        }
        if (page) {
            params.append('page', page.toString());
        }

        return this.fetch<ReleaseNotesList>(`/release-notes?${params.toString()}`);
    }

    async releaseNoteByID(id: string): Promise<ReleaseNote> {
        return this.fetch<ReleaseNote>(`/release-notes/${id}`);
    }

    async createReleaseNote(
        request: CreateReleaseNoteRequest,
    ): Promise<ReleaseNote> {
        return await this.fetch<ReleaseNote>(`/release-notes`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(request),
        });
    }

    async updateReleaseNote(
        id: string,
        request: CreateReleaseNoteRequest,
    ): Promise<ReleaseNote> {
        return this.fetch<ReleaseNote>(`/release-notes/${id}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(request),
        });
    }

    async deleteReleaseNoteByID(id: string): Promise<void> {
        return this.delete(`/release-notes/${id}`, { method: 'DELETE' });
    }

    lastSeenDate(): DateTime {
        const lastSeen = this.webStorage.get<string>(lastSeenKey);
        if (!lastSeen) {
            // magic default date, probably enough in the past to consider every release note as "not seen"
            return DateTime.fromISO('1990-01-01');
        }

        return DateTime.fromISO(lastSeen);
    }

    markLastSeenDate(date: DateTime): void {
        this.webStorage.set<string>(lastSeenKey, date.toISO()!);
    }
}
