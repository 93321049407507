import '@backstage/cli/asset-types';
import { LaunchDarklyProviderComponent } from '@internal/plugin-launchdarkly-react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

(async () => {
  ReactDOM.createRoot(document.getElementById('root')!).render(
    <LaunchDarklyProviderComponent>
      <App />
    </LaunchDarklyProviderComponent>
  );
})();
