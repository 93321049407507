import { TableColumn } from "@backstage/core-components";
import { CatalogTable, CatalogTableRow } from "@backstage/plugin-catalog";
import yaml from 'js-yaml';
import React from "react";
import { getDomainName } from "./Domain.logic";

interface ApiDefinition {
  info?: {
    version?: string;
  };
}

/**
 * Converts a YAML string to an object.
 *
 * @param yamlString
 * @returns ApiDefinition | null
 */
function convertYamlToObject(yamlString: string): ApiDefinition | null {
  try {
    return yaml.load(yamlString) as ApiDefinition;
  } catch (e) {
    //console.error('Error parsing YAML:', e);
    return null;
  }
}

/**
 * Extracts the version number from the API definition as string.
 *
 * @param input
 * @returns string | null
 */
function extractVersionNumber(input: string): string | null {
  if (!input || input.trim().length === 0) {
    return null;
  }

  const yamlContent = convertYamlToObject(input);
  return yamlContent?.info?.version || null;
}

/**
 * Creates a custom column for the version number from OpenAPI definition (yaml).
 *
 * @returns TableColumn<CatalogTableRow>
 */
function createVersionColumn(): TableColumn<CatalogTableRow> {
  return {
    title: 'Version',
    highlight: false,
    width: 'auto',
    align: 'left',
    // TODO: field is not working as expected, need to investigate. We extract the version number from the definition who is YMAL (raw string in definition attrib.).
    // field: 'entity.spec.definition',
    render: (row: CatalogTableRow) => (
      <span>
        {row.entity.spec?.definition && typeof row.entity.spec.definition === 'string'
          ? extractVersionNumber(row.entity.spec.definition)
          : null}
      </span>
    ),
  } as TableColumn<CatalogTableRow>;
}

/**
 * Creates a custom column for the domain name.
 *
 * @returns TableColumn<CatalogTableRow>
 */
function createDomainColumn(): TableColumn<CatalogTableRow> {
  return {
    title: 'Domain',
    highlight: false,
    width: 'auto',
    align: 'left',
    field: 'entity.metadata.labels.caeglobal/domain',
    render: (row: CatalogTableRow) => (
      <span>
        {row.entity.metadata?.labels && 'caeglobal/domain' in row.entity.metadata.labels && typeof row.entity.metadata.labels['caeglobal/domain'] === 'string'
          ? getDomainName(row.entity.metadata.labels['caeglobal/domain'])
          : null}
      </span>
    ),
  } as TableColumn<CatalogTableRow>;
}

/**
 * Custom columns for the catalog table related to API Docs (api catalog).
 *
 * Remove the columns you don't want to display in the catalog table.
 * - CatalogTable.columns.createSystemColumn()
 * - CatalogTable.columns.createOwnerColumn()
 *
 * styling & props: https://material-table.com/#/docs/all-props
 *
 * @returns TableColumn<CatalogTableRow>[]
 */
export const columns: TableColumn<CatalogTableRow>[] = [
  CatalogTable.columns.createTitleColumn({ hidden: true }),
  { ...CatalogTable.columns.createNameColumn({ defaultKind: 'API' }), ...{ width: 'auto' } } as TableColumn<CatalogTableRow>,
  createDomainColumn(),
  createVersionColumn(),
  CatalogTable.columns.createSpecTypeColumn(),
  { ...CatalogTable.columns.createSpecLifecycleColumn(), ...{ width: 'auto' } } as TableColumn<CatalogTableRow>,
  { ...CatalogTable.columns.createMetadataDescriptionColumn(), ...{ width: 'auto' } } as TableColumn<CatalogTableRow>,
  CatalogTable.columns.createTagsColumn(),
];
