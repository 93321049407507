import { OverflowTooltip, TableColumn } from "@backstage/core-components";
import { CatalogTable, CatalogTableColumnsFunc, CatalogTableRow } from "@backstage/plugin-catalog";
import { EntityRefLinks } from "@backstage/plugin-catalog-react";
import React from "react";

function createEmailColumn(): TableColumn<CatalogTableRow> {
  return {
    title: 'Email',
    field: 'entity.spec.profile.email',
  };
};

function createUserGroupsColumn(): TableColumn<CatalogTableRow> {
  return {
    title: 'Groups',
    field: 'entity.spec.memberOf',
    render: ({ entity }) => (
      <EntityRefLinks
        entityRefs={entity.spec?.memberOf}
        defaultKind={'Group'}
      />
    ),
  };
};

function createDescriptionColumn(): TableColumn<CatalogTableRow> {
  return {
    title: 'Description',
    field: 'entity.metadata.description',
    render: ({ entity }) => (
      <OverflowTooltip
        text={entity.metadata.description}
        placement="bottom-start"
      />
    ),
  };
};

function createLifecycleColumn(): TableColumn<CatalogTableRow> {
  return {
    title: 'Lifecycle',
    field: 'entity.spec.lifecycle',
    width: 'auto',
  };
};

export const customColumnsFunction: CatalogTableColumnsFunc = entityListContext => {
  let columns: TableColumn<CatalogTableRow>[] = [];

  switch (entityListContext.filters.kind?.value) {
    case 'component':
      columns = [
        CatalogTable.columns.createNameColumn(),
        CatalogTable.columns.createOwnerColumn(),
        CatalogTable.columns.createSpecTypeColumn(),
        createLifecycleColumn(),
        createDescriptionColumn(),
        CatalogTable.columns.createTagsColumn(),
      ];
      break;
    case 'user':
      columns = [
        CatalogTable.columns.createNameColumn(),
        createEmailColumn(),
        createUserGroupsColumn()
      ];
      break;
    case 'group':
      columns = [
        CatalogTable.columns.createNameColumn(),
        CatalogTable.columns.createSpecTypeColumn(),
        createDescriptionColumn(),
      ];
      break;
    case 'domain':
      columns = [
        CatalogTable.columns.createNameColumn(),
        CatalogTable.columns.createOwnerColumn(),
      ];
      break;
    case 'system':
      columns = [
        CatalogTable.columns.createNameColumn(),
        CatalogTable.columns.createOwnerColumn(),
      ];
      break;
    default:
      columns = CatalogTable.defaultColumnsFunc(entityListContext);
  }

  return columns;
};
