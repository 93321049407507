import { LDContext, asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import config from '../../../config.json';

/**
 * Using Backstage's ConfigApi resulted in weird React errors.
 * So instead, we read a local file containing the id.
 * This file gets overwritten during the build phase and an image is produced for each env.
 */
async function ConfigureLaunchDarklyProvider() {
  const clientSideId = config.launchdarkly.clientSideId;
  console.log('LD clientSideId from config: ', clientSideId);

  const context: LDContext = {
    kind: 'user',
    anonymous: true,
  };

  const ldProvider = await asyncWithLDProvider({
    clientSideID: clientSideId,
    context,
  });

  return ldProvider;
}

/**
 * This is a component which initializes LaunchDarkly's JS SDK (launchdarkly-js-client-sdk) and awaits everything is ready before the app is rendered.
 *
 * @remarks
 * check `asyncWithLDProvider` for differences between async or not.
 *
 * @public
 */
export const LaunchDarklyProviderComponent =
  await ConfigureLaunchDarklyProvider();
