/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 60,
  },
  path: {
    fill: '#2969F2',
  },
});
const LogoFull = () => {
  const classes = useStyles();

  return (
    <svg className={classes.svg} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2079.95 456.05">
      <path
        className={classes.path}
        d="M473.2210083,42.7779503v45.046566h89.9435425
        v40.0318146h-89.9435425v45.7112656H576v40.0244904H428.3285828V2.7530792H576v40.0248718H473.2210083z M108.6842346,216.3827515
        c34.8236389,0,66.813324-14.5550995,86.7065887-42.3388367l-34.0715637-26.9653625
        c-10.0250549,14.7716217-27.4972992,27.9325867-51.4276123,27.9325867c-35.5475006,0-63.393425-29.4109192-63.393425-66.8197632
        s27.8459244-66.8197479,63.393425-66.8197479c23.9303131,0,41.4025574,13.1609573,51.4276123,27.9325714l34.0715637-26.9653625
        C175.4975586,14.5551023,143.5078735,0,108.6842346,0C49.6407623,0,0,47.9830284,0,108.1913757
        S49.6407623,216.3827515,108.6842346,216.3827515z M325.9429626,2.7530792L279.3238831,2.753124l82.0730591,210.8389282h46.9911194
        L325.9429626,2.7530792z M341.2496338,165.199295H262.554657l-18.9277344,48.3927612h-46.509201l48.1439362-124.3680649
        L341.2496338,165.199295z"
      />
    </svg>
  );
};

export default LogoFull;
