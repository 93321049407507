import {
  CatalogIcon,
  Link,
  Sidebar,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarSpace,
  sidebarConfig,
  useSidebarOpenState,
} from '@backstage/core-components';
import { Settings as SidebarSettings, UserSettingsSignInAvatar } from '@backstage/plugin-user-settings';
import { makeStyles } from '@material-ui/core';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import AnnouncementIcon from '@material-ui/icons/Announcement';
// import BuildIcon from '@material-ui/icons/Build';
import { LaunchDarklyComponent, useFeatureFlags } from '@internal/plugin-launchdarkly-react';
import Description from '@material-ui/icons/Description';
import ExtensionIcon from '@material-ui/icons/Extension';
import HomeIcon from '@material-ui/icons/Home';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import React, { PropsWithChildren } from 'react';
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';

const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -34,
    marginLeft: 13,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 5,
  },
});

function SidebarLogo(): JSX.Element {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();

  return (
    <div className={classes.root}>
      <Link to="/" underline="none" className={classes.link} aria-label="Home">
        {isOpen ? <LogoFull /> : <LogoIcon />}
      </Link>
    </div>
  );
}

export function Root({ children }: PropsWithChildren<{}>): JSX.Element {
  const { idpPortalAnnouncements, idpPortalCatalog, idpPortalApis, idpPortalDocumentation, idpPortalTemplates } = useFeatureFlags();

  return (
    <>
      <LaunchDarklyComponent />
      <SidebarPage>
        <Sidebar>
          <SidebarLogo />
          <SidebarGroup label="Search" icon={<SearchIcon/>}>
            {/* <SidebarSearchModal /> */}
            <SidebarItem icon={SearchIcon} to="/search" text="Search" />
          </SidebarGroup>
          <SidebarDivider />
          <SidebarGroup label="Menu" icon={<MenuIcon/>}>
            <SidebarItem icon={HomeIcon} to="/" text="Home" />
            {idpPortalAnnouncements ? <SidebarItem icon={AnnouncementIcon} to="announcements" text="Announcements" /> : <></>}
            {idpPortalCatalog ? <SidebarItem icon={CatalogIcon} to="catalog" text="Catalog" /> : <></>}
            {idpPortalApis ? <SidebarItem icon={ExtensionIcon} to="api-docs" text="APIs" /> : <></>}
            {idpPortalDocumentation ? <SidebarItem icon={LibraryBooks} to="docs" text="Docs" /> : <></>}
            {idpPortalTemplates ? <SidebarItem icon={CreateComponentIcon} to="create" text="Templates" /> : <></>}
            {/* <SidebarItem icon={BuildIcon} to="self-service" text="Self Service" /> */}
          </SidebarGroup>
          <SidebarSpace />
          <SidebarDivider />
          <SidebarGroup label="Settings" icon={<UserSettingsSignInAvatar />} to="/settings">
            <SidebarItem icon={Description} to="release-notes" text="Release Notes"/>
            <SidebarSettings />
          </SidebarGroup>
        </Sidebar>
        {children}
      </SidebarPage>
    </>
  );
}
