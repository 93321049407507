export interface DataMapping {
  [shortName: string]: string;
}

/**
 * Mapping of domain short names to full names.
 */
const data: DataMapping = {
  CrewManagement: "Crew Management",
  CustomerCare: "Customer Care",
  CollabNDocManagement: "Collab & Document Management",
  EngRND: "Engineering and R&D",
  Facilities: "Facilities",
  Finances: "Finances",
  FlightOps: "FlightOps",
  GovcNCompliance: "Governance and Compliance",
  Healthcare: "Healthcare",
  HR: "Human Resources",
  ITManagement: "Information Technology Management",
  Manufacturing: "Manufacturing",
  ProductProjectManagement: "Product and Project Management",
  CyberSecurity: "Cybersecurity",
  SoftDev: "Software Development",
  Training: "Training",
  DataManagement: "Data and AI Management"
};

/**
 * Transforms the keys of the data object to lowercase.
 *
 * @param data
 * @returns DataMapping
 */
function transformKeysToLowercase(data: DataMapping): DataMapping {
  return Object.fromEntries(
    Object.entries(data).map(([key, value]) => [key.toLowerCase(), value])
  );
}

/**
 * Cleans the string by trimming and converting to lowercase.
 *
 * @param str
 * @returns string
 */
function cleanString(str: string): string {
  return str.trim().toLowerCase();
}

const transformedData: DataMapping = transformKeysToLowercase(data);

/**
 * Returns the full name of the domain based on the short name.
 *
 * @param shortName
 * @returns
 */
export function getDomainName(shortName: string): string | null {
  const cleanedShortName = cleanString(shortName);
  return transformedData[cleanedShortName] || null;
}
