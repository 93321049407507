import { EntityKindPicker, EntityLifecyclePicker, EntityOwnerPicker, EntityTagPicker, EntityTypePicker, UserListPicker } from "@backstage/plugin-catalog-react";
import React from "react";

export const CustomFilters = () => {
    const initialKind = "component";
    const allowedKinds = ["component", "template", "domain", "system", "group", "user"];
    return (
      <>
        <EntityKindPicker initialFilter={initialKind} allowedKinds={allowedKinds}/>
        <EntityTypePicker />
        <UserListPicker />
        <EntityOwnerPicker />
        <EntityLifecyclePicker />
        <EntityTagPicker />
      </>
    );
  };
