import { useFlags } from 'launchdarkly-react-client-sdk';

/**
 * Wrapper around LaunchDarkly's hook to retreive all the flags.
 *
 * @remarks
 * This wrapper is useful to remove a dependency to launchdarkly from the backstage frontend.
 *
 * @public
 * @see useFlags
 */
export function useFeatureFlags() {
  return useFlags();
}
